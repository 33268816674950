export default defineAppConfig({
  umami: {
    version: 2,
    debug: true,
    ignoreLocalhost: true,
  },
  ui: {
    primary: 'prop-green',
    gray: 'neutral',
    accent: 'prop-red',
    input: {
      default: {
        size: 'lg',
      },
    },
    select: {
      default: {
        size: 'lg',
      },
    },
    button: {
      default: {
        size: 'md',
      },
    },
    notifications: {
      // Show toasts at the top right of the screen
      position: 'top-0 bottom-auto',
    },
  },
})
