<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UNotifications />
</template>

<script setup lang="ts">
useHead({
  htmlAttrs: {
    lang: 'de',
  },
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/img/hut.png',
    },
  ],
})

useSeoMeta({
  title: 'Propellerfestival',
  description: 'Die offizielle Seite für das Propellerfestival. Hier erfährst du alles, was du über das Festival wissen  musst.',
  ogDescription: 'Die offizielle Seite für das Propellerfestival. Hier erfährst du alles, was du über das Festival wissen  musst.',
  twitterDescription: 'Die offizielle Seite für das Propellerfestival. Hier erfährst du alles, was du über das Festival wissen  musst.',
  ogTitle: 'Propellerfestival',
  twitterTitle: 'Propellerfestival',
  ogImage: '/img/logo.svg',
  twitterImage: '/img/logo.svg',
  twitterCard: 'summary',
  ogUrl: 'https://propellerfestival.de',
})
</script>
