import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
import { default as anmeldungeniS6yVyHpQ4Meta } from "/usr/src/app/pages/admin/anmeldungen.vue?macro=true";
import { default as druckengOwVIAKrHoMeta } from "/usr/src/app/pages/admin/drucken.vue?macro=true";
import { default as bandsZxFsy8A0e4Meta } from "/usr/src/app/pages/admin/einstellungen/bands.vue?macro=true";
import { default as faqsaCEBb0LOi5Meta } from "/usr/src/app/pages/admin/einstellungen/faqs.vue?macro=true";
import { default as startcwb934JLAUMeta } from "/usr/src/app/pages/admin/einstellungen/start.vue?macro=true";
import { default as indexgKB99wg3ORMeta } from "/usr/src/app/pages/admin/index.vue?macro=true";
import { default as _91ticketNumber_93ucjINX8aX6Meta } from "/usr/src/app/pages/admin/scan/[ticketNumber].vue?macro=true";
import { default as indexd74lNSec7BMeta } from "/usr/src/app/pages/admin/scan/index.vue?macro=true";
import { default as ticketsNMoE6zB7eGMeta } from "/usr/src/app/pages/admin/tickets.vue?macro=true";
import { default as adminSfHKkwn4S8Meta } from "/usr/src/app/pages/admin.vue?macro=true";
import { default as anfahrtBFwTz2BMJfMeta } from "/usr/src/app/pages/anfahrt.vue?macro=true";
import { default as index2bLf59TVvaMeta } from "/usr/src/app/pages/benutzer/index.vue?macro=true";
import { default as datenschutzBjdGXvboxrMeta } from "/usr/src/app/pages/datenschutz.vue?macro=true";
import { default as faqsyEOqTc8VH9Meta } from "/usr/src/app/pages/faqs.vue?macro=true";
import { default as forgotpasswordTesBU8lOUvMeta } from "/usr/src/app/pages/forgotpassword.vue?macro=true";
import { default as impressum8rTlfYuIk3Meta } from "/usr/src/app/pages/impressum.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as jahrezFLFuirmbhMeta } from "/usr/src/app/pages/jahre.vue?macro=true";
import { default as kontaktiNBlSaGxQZMeta } from "/usr/src/app/pages/kontakt.vue?macro=true";
import { default as kulturstadel31CgaDzn9wMeta } from "/usr/src/app/pages/kulturstadel.vue?macro=true";
import { default as lineupA6kteM3TOYMeta } from "/usr/src/app/pages/lineup.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as _91resetToken_938AsH6inv5oMeta } from "/usr/src/app/pages/resetpassword/[resetToken].vue?macro=true";
import { default as erfolgYtatngo9CLMeta } from "/usr/src/app/pages/resetpassword/erfolg.vue?macro=true";
import { default as teilnahmePw81qdXKBBMeta } from "/usr/src/app/pages/teilnahme.vue?macro=true";
import { default as erfolgreichwHsrx2ZI3QMeta } from "/usr/src/app/pages/ticket/erfolgreich.vue?macro=true";
import { default as fehlgeschlagenCfdhQZhAq3Meta } from "/usr/src/app/pages/ticket/fehlgeschlagen.vue?macro=true";
import { default as indexqtEYX73HudMeta } from "/usr/src/app/pages/ticket/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93pvGVZEAWcBMeta?.name ?? "slug",
    path: _91_46_46_46slug_93pvGVZEAWcBMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    alias: _91_46_46_46slug_93pvGVZEAWcBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pvGVZEAWcBMeta?.redirect,
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: adminSfHKkwn4S8Meta?.name ?? undefined,
    path: adminSfHKkwn4S8Meta?.path ?? "/admin",
    meta: adminSfHKkwn4S8Meta || {},
    alias: adminSfHKkwn4S8Meta?.alias || [],
    redirect: adminSfHKkwn4S8Meta?.redirect,
    component: () => import("/usr/src/app/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: anmeldungeniS6yVyHpQ4Meta?.name ?? "admin-anmeldungen",
    path: anmeldungeniS6yVyHpQ4Meta?.path ?? "anmeldungen",
    meta: anmeldungeniS6yVyHpQ4Meta || {},
    alias: anmeldungeniS6yVyHpQ4Meta?.alias || [],
    redirect: anmeldungeniS6yVyHpQ4Meta?.redirect,
    component: () => import("/usr/src/app/pages/admin/anmeldungen.vue").then(m => m.default || m)
  },
  {
    name: druckengOwVIAKrHoMeta?.name ?? "admin-drucken",
    path: druckengOwVIAKrHoMeta?.path ?? "drucken",
    meta: druckengOwVIAKrHoMeta || {},
    alias: druckengOwVIAKrHoMeta?.alias || [],
    redirect: druckengOwVIAKrHoMeta?.redirect,
    component: () => import("/usr/src/app/pages/admin/drucken.vue").then(m => m.default || m)
  },
  {
    name: bandsZxFsy8A0e4Meta?.name ?? "admin-einstellungen-bands",
    path: bandsZxFsy8A0e4Meta?.path ?? "einstellungen/bands",
    meta: bandsZxFsy8A0e4Meta || {},
    alias: bandsZxFsy8A0e4Meta?.alias || [],
    redirect: bandsZxFsy8A0e4Meta?.redirect,
    component: () => import("/usr/src/app/pages/admin/einstellungen/bands.vue").then(m => m.default || m)
  },
  {
    name: faqsaCEBb0LOi5Meta?.name ?? "admin-einstellungen-faqs",
    path: faqsaCEBb0LOi5Meta?.path ?? "einstellungen/faqs",
    meta: faqsaCEBb0LOi5Meta || {},
    alias: faqsaCEBb0LOi5Meta?.alias || [],
    redirect: faqsaCEBb0LOi5Meta?.redirect,
    component: () => import("/usr/src/app/pages/admin/einstellungen/faqs.vue").then(m => m.default || m)
  },
  {
    name: startcwb934JLAUMeta?.name ?? "admin-einstellungen-start",
    path: startcwb934JLAUMeta?.path ?? "einstellungen/start",
    meta: startcwb934JLAUMeta || {},
    alias: startcwb934JLAUMeta?.alias || [],
    redirect: startcwb934JLAUMeta?.redirect,
    component: () => import("/usr/src/app/pages/admin/einstellungen/start.vue").then(m => m.default || m)
  },
  {
    name: indexgKB99wg3ORMeta?.name ?? "admin",
    path: indexgKB99wg3ORMeta?.path ?? "",
    meta: indexgKB99wg3ORMeta || {},
    alias: indexgKB99wg3ORMeta?.alias || [],
    redirect: indexgKB99wg3ORMeta?.redirect,
    component: () => import("/usr/src/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91ticketNumber_93ucjINX8aX6Meta?.name ?? "admin-scan-ticketNumber",
    path: _91ticketNumber_93ucjINX8aX6Meta?.path ?? "scan/:ticketNumber()",
    meta: _91ticketNumber_93ucjINX8aX6Meta || {},
    alias: _91ticketNumber_93ucjINX8aX6Meta?.alias || [],
    redirect: _91ticketNumber_93ucjINX8aX6Meta?.redirect,
    component: () => import("/usr/src/app/pages/admin/scan/[ticketNumber].vue").then(m => m.default || m)
  },
  {
    name: indexd74lNSec7BMeta?.name ?? "admin-scan",
    path: indexd74lNSec7BMeta?.path ?? "scan",
    meta: indexd74lNSec7BMeta || {},
    alias: indexd74lNSec7BMeta?.alias || [],
    redirect: indexd74lNSec7BMeta?.redirect,
    component: () => import("/usr/src/app/pages/admin/scan/index.vue").then(m => m.default || m)
  },
  {
    name: ticketsNMoE6zB7eGMeta?.name ?? "admin-tickets",
    path: ticketsNMoE6zB7eGMeta?.path ?? "tickets",
    meta: ticketsNMoE6zB7eGMeta || {},
    alias: ticketsNMoE6zB7eGMeta?.alias || [],
    redirect: ticketsNMoE6zB7eGMeta?.redirect,
    component: () => import("/usr/src/app/pages/admin/tickets.vue").then(m => m.default || m)
  }
]
  },
  {
    name: anfahrtBFwTz2BMJfMeta?.name ?? "anfahrt",
    path: anfahrtBFwTz2BMJfMeta?.path ?? "/anfahrt",
    meta: anfahrtBFwTz2BMJfMeta || {},
    alias: anfahrtBFwTz2BMJfMeta?.alias || [],
    redirect: anfahrtBFwTz2BMJfMeta?.redirect,
    component: () => import("/usr/src/app/pages/anfahrt.vue").then(m => m.default || m)
  },
  {
    name: index2bLf59TVvaMeta?.name ?? "benutzer",
    path: index2bLf59TVvaMeta?.path ?? "/benutzer",
    meta: index2bLf59TVvaMeta || {},
    alias: index2bLf59TVvaMeta?.alias || [],
    redirect: index2bLf59TVvaMeta?.redirect,
    component: () => import("/usr/src/app/pages/benutzer/index.vue").then(m => m.default || m)
  },
  {
    name: datenschutzBjdGXvboxrMeta?.name ?? "datenschutz",
    path: datenschutzBjdGXvboxrMeta?.path ?? "/datenschutz",
    meta: datenschutzBjdGXvboxrMeta || {},
    alias: datenschutzBjdGXvboxrMeta?.alias || [],
    redirect: datenschutzBjdGXvboxrMeta?.redirect,
    component: () => import("/usr/src/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: faqsyEOqTc8VH9Meta?.name ?? "faqs",
    path: faqsyEOqTc8VH9Meta?.path ?? "/faqs",
    meta: faqsyEOqTc8VH9Meta || {},
    alias: faqsyEOqTc8VH9Meta?.alias || [],
    redirect: faqsyEOqTc8VH9Meta?.redirect,
    component: () => import("/usr/src/app/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: forgotpasswordTesBU8lOUvMeta?.name ?? "forgotpassword",
    path: forgotpasswordTesBU8lOUvMeta?.path ?? "/forgotpassword",
    meta: forgotpasswordTesBU8lOUvMeta || {},
    alias: forgotpasswordTesBU8lOUvMeta?.alias || [],
    redirect: forgotpasswordTesBU8lOUvMeta?.redirect,
    component: () => import("/usr/src/app/pages/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: impressum8rTlfYuIk3Meta?.name ?? "impressum",
    path: impressum8rTlfYuIk3Meta?.path ?? "/impressum",
    meta: impressum8rTlfYuIk3Meta || {},
    alias: impressum8rTlfYuIk3Meta?.alias || [],
    redirect: impressum8rTlfYuIk3Meta?.redirect,
    component: () => import("/usr/src/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: jahrezFLFuirmbhMeta?.name ?? "jahre",
    path: jahrezFLFuirmbhMeta?.path ?? "/jahre",
    meta: jahrezFLFuirmbhMeta || {},
    alias: jahrezFLFuirmbhMeta?.alias || [],
    redirect: jahrezFLFuirmbhMeta?.redirect,
    component: () => import("/usr/src/app/pages/jahre.vue").then(m => m.default || m)
  },
  {
    name: kontaktiNBlSaGxQZMeta?.name ?? "kontakt",
    path: kontaktiNBlSaGxQZMeta?.path ?? "/kontakt",
    meta: kontaktiNBlSaGxQZMeta || {},
    alias: kontaktiNBlSaGxQZMeta?.alias || [],
    redirect: kontaktiNBlSaGxQZMeta?.redirect,
    component: () => import("/usr/src/app/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: kulturstadel31CgaDzn9wMeta?.name ?? "kulturstadel",
    path: kulturstadel31CgaDzn9wMeta?.path ?? "/kulturstadel",
    meta: kulturstadel31CgaDzn9wMeta || {},
    alias: kulturstadel31CgaDzn9wMeta?.alias || [],
    redirect: kulturstadel31CgaDzn9wMeta?.redirect,
    component: () => import("/usr/src/app/pages/kulturstadel.vue").then(m => m.default || m)
  },
  {
    name: lineupA6kteM3TOYMeta?.name ?? "lineup",
    path: lineupA6kteM3TOYMeta?.path ?? "/lineup",
    meta: lineupA6kteM3TOYMeta || {},
    alias: lineupA6kteM3TOYMeta?.alias || [],
    redirect: lineupA6kteM3TOYMeta?.redirect,
    component: () => import("/usr/src/app/pages/lineup.vue").then(m => m.default || m)
  },
  {
    name: loginRGcvZC480vMeta?.name ?? "login",
    path: loginRGcvZC480vMeta?.path ?? "/login",
    meta: loginRGcvZC480vMeta || {},
    alias: loginRGcvZC480vMeta?.alias || [],
    redirect: loginRGcvZC480vMeta?.redirect,
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91resetToken_938AsH6inv5oMeta?.name ?? "resetpassword-resetToken",
    path: _91resetToken_938AsH6inv5oMeta?.path ?? "/resetpassword/:resetToken()",
    meta: _91resetToken_938AsH6inv5oMeta || {},
    alias: _91resetToken_938AsH6inv5oMeta?.alias || [],
    redirect: _91resetToken_938AsH6inv5oMeta?.redirect,
    component: () => import("/usr/src/app/pages/resetpassword/[resetToken].vue").then(m => m.default || m)
  },
  {
    name: erfolgYtatngo9CLMeta?.name ?? "resetpassword-erfolg",
    path: erfolgYtatngo9CLMeta?.path ?? "/resetpassword/erfolg",
    meta: erfolgYtatngo9CLMeta || {},
    alias: erfolgYtatngo9CLMeta?.alias || [],
    redirect: erfolgYtatngo9CLMeta?.redirect,
    component: () => import("/usr/src/app/pages/resetpassword/erfolg.vue").then(m => m.default || m)
  },
  {
    name: teilnahmePw81qdXKBBMeta?.name ?? "teilnahme",
    path: teilnahmePw81qdXKBBMeta?.path ?? "/teilnahme",
    meta: teilnahmePw81qdXKBBMeta || {},
    alias: teilnahmePw81qdXKBBMeta?.alias || [],
    redirect: teilnahmePw81qdXKBBMeta?.redirect,
    component: () => import("/usr/src/app/pages/teilnahme.vue").then(m => m.default || m)
  },
  {
    name: erfolgreichwHsrx2ZI3QMeta?.name ?? "ticket-erfolgreich",
    path: erfolgreichwHsrx2ZI3QMeta?.path ?? "/ticket/erfolgreich",
    meta: erfolgreichwHsrx2ZI3QMeta || {},
    alias: erfolgreichwHsrx2ZI3QMeta?.alias || [],
    redirect: erfolgreichwHsrx2ZI3QMeta?.redirect,
    component: () => import("/usr/src/app/pages/ticket/erfolgreich.vue").then(m => m.default || m)
  },
  {
    name: fehlgeschlagenCfdhQZhAq3Meta?.name ?? "ticket-fehlgeschlagen",
    path: fehlgeschlagenCfdhQZhAq3Meta?.path ?? "/ticket/fehlgeschlagen",
    meta: fehlgeschlagenCfdhQZhAq3Meta || {},
    alias: fehlgeschlagenCfdhQZhAq3Meta?.alias || [],
    redirect: fehlgeschlagenCfdhQZhAq3Meta?.redirect,
    component: () => import("/usr/src/app/pages/ticket/fehlgeschlagen.vue").then(m => m.default || m)
  },
  {
    name: indexqtEYX73HudMeta?.name ?? "ticket",
    path: indexqtEYX73HudMeta?.path ?? "/ticket",
    meta: indexqtEYX73HudMeta || {},
    alias: indexqtEYX73HudMeta?.alias || [],
    redirect: indexqtEYX73HudMeta?.redirect,
    component: () => import("/usr/src/app/pages/ticket/index.vue").then(m => m.default || m)
  }
]