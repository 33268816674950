import { skipHydrate } from 'pinia'

export const useUserStore = defineStore('useUserStore', () => {
  const user: Ref<User | null> = useCookie('prop:user', {
    default: () => null,
    secure: true,
    sameSite: true,
  })
  const session: Ref<Session | null> = useCookie('prop:session', {
    default: () => null,
    secure: true,
    sameSite: true,
  })

  // Getter for login state
  const isAuthenticated = computed(() => {
    // Check if the session is there
    if (!session.value) {
      return false
    }

    // Check if the token is expired
    const currentDate = new Date()
    if (new Date(session.value.accessTokenExpiresAt) < currentDate) {
      return false
    }

    return true
  })

  // Compute the logged in state
  const isLoggedIn = computed(() => {
    // Check the local storage and store it into the user ref if available
    return user.value !== null
  })

  // Login the user
  async function loginUser(username: string, password: string) {
    const state: UserForm = {
      username: username,
      password: password,
    }

    const data = await $fetch<LoginResponse>('/api/auth/login', {
      method: 'POST',
      body: JSON.stringify(state),
      onResponseError(context) {
        const error = context.response._data as RestErr
        throw error
      },
    })
    console.log('login successful')
    // Save the data
    saveLoginData(data)
  }

  // Saves the login data to the cookie
  function saveLoginData(data: LoginResponse) {
    const toCookieSession: Session = {
      token: data.token,
      accessTokenExpiresAt: data.expires_at,
    }
    session.value = toCookieSession
    user.value = data.user
    refreshCookie('prop:session')
  }

  // Logs out the user
  function logoutUser() {
    console.log('logging out user')

    // await $fetch<ResponseMessage | RestErr>('/api/auth/logout')

    user.value = null
    session.value = null
    refreshCookie('prop:session')
    refreshCookie('prop:user')
    // Clear the local storage and the session storage
    localStorage.clear()
    sessionStorage.clear()
  }

  // Make th password forgot call to the api with the given email
  async function forgotPassword(email: string) {
    await $fetch<ResponseMessage>('/api/auth/forgotpassword', {
      method: 'POST',
      body: {
        email: email,
      },
      onResponseError(context) {
        const error = context.response._data as RestErr
        throw error
      },
    })
  }

  // Make th password reset call to the api with the new password and the token
  async function resetPassword(password: string, password_confirmation: string, resetToken: string) {
    await $fetch<ResponseMessage>('/api/auth/resetpassword/' + resetToken, {
      method: 'PATCH',
      body: {
        password: password,
        password_confirmation: password_confirmation,
      },
      onResponseError(context) {
        const error = context.response._data as RestErr
        throw error
      },
    })
  }

  return {
    user: skipHydrate(user),
    session: skipHydrate(session),
    isAuthenticated,
    isLoggedIn,
    loginUser,
    logoutUser,
    forgotPassword,
    resetPassword,
  }
})
