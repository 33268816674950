export default defineNuxtRouteMiddleware(async (to) => {
  // Array of routes that don't need authentication
  const unauthenticatedRoutes: string[] = ['/register', '/login', '/forgotpassword', '/resetpassword', '/resetpassword/erfolg']
  const authenticatedRoutes: string[] = ['/benutzer', '/admin']

  const { isAuthenticated } = storeToRefs(useUserStore())

  if (isAuthenticated.value && hasPartOfPathInArray(to.path, unauthenticatedRoutes)) {
    return navigateTo('/')
  }

  // if the user is not authenticated and the route is in the authenticatedRoutes array, redirect to the login page
  if (!isAuthenticated.value && hasPartOfPathInArray(to.path, authenticatedRoutes)) {
    return navigateTo('/login')
  }
})

// Function to check if the path starts with any of the items in the array
function hasPartOfPathInArray(path: string, pathArray: string[]): boolean {
  let result = false

  // For each item in the array, check if the path starts with the item. If yes return true
  pathArray.forEach((pathFromArray) => {
    if (path.startsWith(pathFromArray)) {
      result = true
    }
  })

  return result
}
