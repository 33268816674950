<template>
  <div class="error-page container items-center">
    <h1 class="-skew-x-6 -rotate-1 text-5xl md:text-7xl font-black mb-10 break-words">{{ error.statusCode }}</h1>
    <h2>{{ error.message }}</h2>
    <p>Hoppla! Etwas ist schiefgelaufen.</p>
    <p>Keine Sorge, unser Team hochqualifizierter Affen arbeitet hart daran, dieses Problem zu beheben.</p>
    <p>In der Zwischenzeit können Sie versuchen, die Seite zu aktualisieren oder später zurückzukommen.</p>
    <button @click="handleError">Zurück zur Startseite</button>
  </div>
</template>

<script setup>
const props = defineProps({
  error: Object,
})

const handleError = () => clearError({ redirect: '/' })
</script>

<style scoped>
.error-page {
  text-align: center;
  margin-top: 100px;
  font-family: 'Comic Sans MS', cursive;
  color: red;
}
</style>
